@import url('https://fonts.googleapis.com/css2?family=Teko:wght@300;400;500;600;700&display=swap');
@font-face {
 font-family: 'eurosoftitalic';
 src: url('../fonts/eurosoft_italic-webfont.woff2') format('woff2'), url('../fonts/eurosoft_italic-webfont.woff') format('woff');
 font-weight: normal;
 font-style: normal;
}
@font-face {
 font-family: 'eurosoftsemibold_italic';
 src: url('../fonts/eurosoft_semibold_italic-webfont.woff2') format('woff2'), url('../fonts/eurosoft_semibold_italic-webfont.woff') format('woff');
 font-weight: normal;
 font-style: normal;
}
@font-face {
 font-family: 'eurosoftsemibold';
 src: url('../fonts/eurosoft_semibold-webfont.woff2') format('woff2'), url('../fonts/eurosoft_semibold-webfont.woff') format('woff');
 font-weight: normal;
 font-style: normal;
}
@font-face {
 font-family: 'eurosoftregular';
 src: url('../fonts/eurosoft_regular-webfont.woff2') format('woff2'), url('../fonts/eurosoft_regular-webfont.woff') format('woff');
 font-weight: normal;
 font-style: normal;
}
@font-face {
 font-family: 'eurosoftmedium_italic';
 src: url('../fonts/eurosoft_medium_italic-webfont.woff2') format('woff2'), url('../fonts/eurosoft_medium_italic-webfont.woff') format('woff');
 font-weight: normal;
 font-style: normal;
}
@font-face {
 font-family: 'eurosoftmedium';
 src: url('../fonts/eurosoft_medium-webfont.woff2') format('woff2'), url('../fonts/eurosoft_medium-webfont.woff') format('woff');
 font-weight: normal;
 font-style: normal;
}
@font-face {
 font-family: 'eurosoftlight_italic';
 src: url('../fonts/eurosoft_light_italic-webfont.woff2') format('woff2'), url('../fonts/eurosoft_light_italic-webfont.woff') format('woff');
 font-weight: normal;
 font-style: normal;
}
@font-face {
 font-family: 'eurosoftlight';
 src: url('../fonts/eurosoft_light-webfont.woff2') format('woff2'), url('../fonts/eurosoft_light-webfont.woff') format('woff');
 font-weight: normal;
 font-style: normal;
}
@font-face {
 font-family: 'eurosoftbold';
 src: url('../fonts/eurosoft_bold-webfont.woff2') format('woff2'), url('../fonts/eurosoft_bold-webfont.woff') format('woff');
 font-weight: normal;
 font-style: normal;
}

@font-face {
    font-family: 'Bryant Pro Regular';
    src: url('../fonts/BryantPro-RegularItalic.woff2') format('woff2'),
        url('../fonts/BryantPro-RegularItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Bryant Pro';
    src: url('../fonts/BryantPro-Bold.woff2') format('woff2'),
        url('../fonts/BryantPro-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Bryant Pro';
    src: url('../fonts/BryantPro-MediumItalic.woff2') format('woff2'),
        url('../fonts/BryantPro-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Bryant Pro';
    src: url('../fonts/BryantPro-Medium.woff2') format('woff2'),
        url('../fonts/BryantPro-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Bryant Pro Bold Alternate';
    src: url('../fonts/BryantPro-BoldAlternate.woff2') format('woff2'),
        url('../fonts/BryantPro-BoldAlternate.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Bryant Pro';
    src: url('../fonts/BryantPro-LightItalic.woff2') format('woff2'),
        url('../fonts/BryantPro-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Bryant Pro Medium Alternate';
    src: url('../fonts/BryantPro-MediumAlternate.woff2') format('woff2'),
        url('../fonts/BryantPro-MediumAlternate.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Bryant Pro Light Alternate';
    src: url('../fonts/BryantPro-LightAlternate.woff2') format('woff2'),
        url('../fonts/BryantPro-LightAlternate.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Bryant Pro Regular Alternate';
    src: url('../fonts/BryantPro-RegularAlternate.woff2') format('woff2'),
        url('../fonts/BryantPro-RegularAlternate.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Bryant Pro';
    src: url('../fonts/BryantPro-Regular.woff2') format('woff2'),
        url('../fonts/BryantPro-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Bryant Pro';
    src: url('../fonts/BryantPro-Light.woff2') format('woff2'),
        url('../fonts/BryantPro-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Bryant Pro';
    src: url('../fonts/BryantPro-BoldItalic.woff2') format('woff2'),
        url('../fonts/BryantPro-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}
* {margin: 0; padding: 0; font-family: 'Teko', sans-serif;
-webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale}
body, html {height: 100%}

.banner {background: url("../images/bg_verao.jpg") no-repeat; background-size: cover; background-position: center center;}
.banner h1 {text-align: center; padding: 40px 0;}
.banner h1 svg {width: 150px; height: auto; display: inline-block; vertical-align: middle;}
.banner h1 span {display: inline-block; vertical-align: top; text-align: left; color: #fff; border-left: 1px solid rgba(248,248,248,0.5); font-size: 24px; font-weight: 400; font-family: 'eurosoftregular'; padding: 14px 0 20px 35px; margin-left: 30px;}
.banner h1 span b {font-family: 'eurosoftsemibold'; display: block;}
.banner h1 span strong {font-family: 'eurosoftsemibold';}

.content {position: relative;}
.modelos {text-align: center;}
.modelos img { width: 100%; height: auto; display: block;}
.benefits {position: absolute; bottom: 29%; width: 100%;}
.box {background-image: linear-gradient(to left bottom, #2e2e2e, #242424, #1a1a1a, #101010, #000000); border: 1px solid #dd3e49; padding: 25px 20px 20px 20px; text-align: center; border-radius: 20px; margin: 7px; -webkit-box-shadow: 0px 0px 32px 0px rgba(198,0,0,0.31); box-shadow: 0px 0px 32px 0px rgba(198,0,0,0.31);}
.box h3 {font-family: 'eurosoftmedium'; font-size: 25px; color: #dd3e49; }
.box span {color: #fff; font-size: 24px; position: relative; font-family: 'eurosoftlight'; display: block; margin-top: 7px;}
.box span::after {content:""; display: block; position: absolute; width: 80%; height: 2px; background-color: #dd3e49; top: 50%; left: 10%; }
.box h4 {font-family: 'eurosoftsemibold'; font-size: 50px; color: #fff; }

.center_content {margin: 0 auto;}

.price {}
.price h2 {color: #fff; font-family: 'eurosoftmedium'; font-size: 34px; text-align: left; margin-top: 60px; }
.price h2 span {color: #fff; font-family: 'eurosoftmedium';  padding-top: 4px; background-color: #d91824; display: inline-block; border-radius: 0 15px 0 15px; padding: 0 15px; margin: 7px 0;}
.price h2 strong {color: #fff; font-family: 'eurosoftbold'; display: block;}
.price p {display: inline-block; color: #fff; position: relative; font-size: 32px; font-family: 'eurosoftregular'; margin-top: 15px; text-align: left; }
.price .center_price {margin: 0 auto; display: inline-block; padding-top: 20px;}
.price .mensalidade {font-family: 'eurosoftlight'; font-size: 32px; color: #fff; margin-top: -7px;}
.price .mensalidade strong {font-family: 'eurosoftsemibold';}
.price p::after {content:""; display: block; position: absolute; width: 120%; height: 2px; background-color: #dd3e49; top: 50%; left: -10%; }
.price .anuidade {font-size: 16px; text-align: center !important; width: 100%; padding-bottom: 15px;}
.price .anuidade::after {display: none;}
.price .valor { margin-top: -20px; ;}
.price .valor span {display: inline-block; color: #fff; font-family: 'eurosoftmedium'; font-size: 26px; vertical-align: top; line-height: 82px; padding-right: 5px}
.price .valor strong {display: inline-block; color: #fff; font-family: 'eurosoftsemibold'; font-size: 112px;}
.price .valor em {display: inline-block; color: #fff; font-family: 'eurosoftsemibold'; font-size: 46px; font-style: normal;}
.unidade {padding-top: 15px;}
.unidade select {appearance: none; outline: 0;border: 1px solid #fff;;box-shadow: none;flex: 1;padding: 0 1em; color: #fff;background-color: transparent;cursor: pointer; width: 100%;}
/* Remove IE arrow */
.unidade select::-ms-expand {display: none;}
/* Custom Select wrapper */
.unidade .select { position: relative;display: flex;width: 100%;min-height: 60px;border-radius: 10px;overflow: hidden; font-family: 'eurosoftmedium'; font-size: 22px;}
/* Arrow */
.unidade div {position: relative; margin-bottom: 20px;}
.unidade div::after {content: "\25BC";position: absolute;top: 0;right: 0; background-color: #dd3e49; transition: .25s all ease; pointer-events: none; color: #fff; border-radius: 0 10px 10px 0; min-height: 60px; width: 12%; text-align: center; line-height: 60px;}
.unidade .inative {pointer-events: none; opacity: 0.5;}
.unidade a.select {border: 1px solid #fff; display: block; color: #fff; text-decoration: none; line-height: 58px; padding: 0 1em;}
.unidade a.select div {font-family: 'eurosoftmedium'; margin-bottom: 0; }
.unidade a.select .unit {margin-bottom: 0; font-family: 'eurosoftmedium'; line-height: 25px; padding-top: 7px;}
.unidade a.select div::after {display: none;}
.unidade a.select span {display: block; font-size: 17px;}
.unidade #unidade {max-height: 200px; position: absolute; list-style: none; padding: 20px; background-color: rgba(0,0,0,0.8); z-index: 999; width: 100%; top: 0;overflow: auto; display: none;}
.unidade #unidade:target {display: block;}
.unidade #unidade li {display: block; padding-bottom: 10px;}
.unidade #unidade li a {display: block; color: #fff; font-family: 'eurosoftsemibold'; text-decoration: none; font-size: 22px;}
.unidade #unidade li a span {display: block; font-size: 20px; }
.unidade #unidade li a:hover {color:#dd3e49; }
.price .button {width: 100%; border: 0; padding: 20px; background-image: linear-gradient(to right top, #930001, #a60002, #b90002, #cd0001, #e10000); border-radius: 15px; font-family: 'eurosoftsemibold'; font-size: 50px; color: #fff; cursor: pointer; text-decoration: none; font-size: 26px; width: 100%; display: block; text-align: center; text-transform: uppercase;}

.type_plan {font-size:27px; color: #fff; text-transform: uppercase; font-family: 'eurosoftlight'; margin: -15px 0 0 41px;}
.type_plan strong {font-family: 'eurosoftsemibold';}

.area_02 {background: url("../images/bg_footer.jpg") no-repeat; background-size: 100%; background-size: 50% 100%; padding: 90px 0 40px 0; background-position: right;}
.area_02 ul {list-style: none; padding-left: 20px;}
.area_02 ul li {margin-bottom: 10px;}
.area_02 ul i { display: inline-block; vertical-align: middle; width: 15%; padding-bottom: 30px;}
.area_02 ul span {font-size: 22px; color: #000; font-family: 'eurosoftregular'; display: inline-block; vertical-align: middle; width: 80%; border-bottom: 1px dashed #000; padding-bottom: 30px;}
.area_02 ul li:last-child {margin-top: 20px;}
.area_02 ul li:last-child span {border: 0;}

footer {background-color: #B50000; text-align: center; padding: 30px 0;}
footer img {width: 60px; height: auto; filter: brightness(500%);}
footer p {font-size: 16px; color: #fff; padding-top: 5px; font-family: 'eurosoftregular';}

.modal {position: fixed; top: 0; z-index: 99999; background-color: rgba(0,0,0,0.9); width: 100%; padding: 50px 0 50px 0; height: 100%; display: none;}
.modal:target {display: block;}
.modal .container {height: 100%;}
.modal h5 {font-size: 30px; color: #fff; position: relative; margin-bottom: 40px;  overflow: hidden; font-family: 'eurosoftsemibold';}
.modal h5 a  {position: absolute; right: 0; top: 0;}
.modal h5 em {font-size: 16px; display: block; font-family: 'eurosoftregular'; font-style: normal;}
.modal h5 a svg {width: 30px; height: 30px; color: #fff; margin-top: 5px;}
.faq-content { width: 35%; margin: 0 auto; background-image: linear-gradient(to left bottom, #2e2e2e, #242424, #1a1a1a, #101010, #000000); padding: 25px; position: relative; overflow: hidden; height: 100%;}
.faq-content label {display: block; padding: 10px 0;font-size: 24px; color: #fff; font-weight: 700; width: 98%; cursor: pointer; }
.faq-content label span {font-family: 'eurosoftregular';}
.faq-content label:first-child {padding-top: 0;}
.faq-content label:last-child {border: none;}
.accordion {overflow-y: auto; height: 84%; position: relative;}
.accordion::-webkit-scrollbar-track { -webkit-box-shadow: inset 0 0 6px rgba(222,35,33,0.7); background-color: #ff000;}
.accordion::-webkit-scrollbar{width: 1px; background-color: #f0000;}
.accordion::-webkit-scrollbar-thumb{ background-color: #fff;border: 0;}
.accordion span {display: block; cursor: pointer; position: relative; padding-right: 0;}
.accordion input {display: none;}
.accordion ul {list-style:none; display: none; font-size: 18px; font-weight: 400; margin: 20px 20px 0 0;}
.accordion ul li { padding: 0;  margin-bottom: 10px; background-image: linear-gradient(to left bottom, #2e2e2e, #242424, #1a1a1a, #101010, #000000);}
.accordion ul li a {color: #fff; text-decoration: none; font-size: 20px; font-family: 'eurosoftregular'; position: relative; padding: 12px 45px 12px 12px; border: 1px solid #de2321; border-radius: 10px; display: block; overflow: hidden; height: 100%;}
.accordion ul li a em {display: block; font-size: 18px; font-style: normal;}
.accordion ul li a i {position: absolute; right: 0; top: 0;  background-color: #de2321; display: block; height: 100%; width: 45px;}
.accordion ul li a i svg { position: absolute;  width: 30px; height: 30px; fill: #fff; display: inline-block; vertical-align: middle; top: 50%; margin-top: -15px; right: 5px;}
.accordion input:checked ~ ul {display: block;}
.accordion input:checked ~ span {color: #de2321;}
.card-content { width: 35%; margin: 0 auto; background-image: linear-gradient(to left bottom, #2e2e2e, #242424, #1a1a1a, #101010, #000000); padding: 20px; position: relative; overflow: hidden; height: auto;}
.card-content .plan {color: #fff; font-family: 'eurosoftregular'; font-size: 20px; overflow: hidden;}
.card-content .plan span {float: right; font-family: 'eurosoftregular';}
.card-content .plan strong {font-family: 'eurosoftsemibold'; text-transform: uppercase;}
.card-content .plan p {font-family: 'eurosoftregular'; background-color: #de2321; display: block; padding: 0 10px; line-height: 30px; margin-top: 5px; margin: 10px; clear: both; text-align: center; border-radius: 30px;}
.card-content h6 {color: #fff; font-family: 'eurosoftsemibold'; margin-top: 10px; font-size: 20px; margin-bottom: 10px; border-top: 1px dashed rgba(255,255,255,0.5); padding-top: 10px; }
.card-content ul {color: #fff; float: left; width: 50%; overflow: hidden; padding-bottom: 5px;}
.card-content ul li {font-family: 'eurosoftregular'; font-size: 16px; padding-bottom: 10px; margin-left: 15px; padding-left: 10px; padding-right: 20px;}
.card-content h5 {margin-bottom: 11px; font-size: 22px; padding-bottom: 15px; border-bottom: 1px solid rgba(255,255,255,0.5);}
.card-content .valores {clear: both; margin-top: 20px; border-top: 1px dashed rgba(255,255,255,0.5);}
.card-content .valores p {color: #fff; font-family: 'eurosoftregular'; font-size: 16px; margin: 10px 0 15px 0; line-height: 22px; }
.card-content .valores p strong {font-family: 'eurosoftsemibold';}
.card-content .button {width: 100%; border: 0; padding: 15px; background-image: linear-gradient(to right top, #930001, #a60002, #b90002, #cd0001, #e10000); border-radius: 15px; font-family: 'eurosoftsemibold'; font-size: 50px; color: #fff; cursor: pointer; text-decoration: none; font-size: 24px; width: 100%; display: block; text-align: center; text-transform: uppercase;}

@media (max-width:768px) {
 .banner {padding-bottom: 30px;}
 .banner h1 {padding: 40px 0 0 0;}
 .banner h1 i {display: inline-block;}
 .banner h1 svg {width: 100px;}
 .banner h1 span {width: auto;  margin-left: 10px; font-size: 16px; display: inline-block; padding: 15px 0 0 15px;}
 .benefits {position: relative; bottom: 0; margin-top: -80px;}
 .benefits .col {width: 46%; margin-right: 4%;}
 .benefits .col:last-child {margin-right: 0;}
 .benefits .box {padding: 12px; margin: 0;}
 .benefits .box h3 {font-size: 17px;}
 .benefits .box span {font-size: 16px;}
 .modelos img {width: 90%;}
 .box span::after {width: 100%; left: 0;}
 .benefits .box h4 {font-size: 30px;}
 .price .center_price {width: 230px; display: block;}
 .price h2 {font-size: 26px; margin-top: 25px; text-align: center;}
 .price h2 span {display: inline-block; font-size: 26px;}
 .price h2 strong {font-size: 24px;}
 .price p {font-size: 22px;}
 .price .valor span {font-size: 22px; line-height: 56px;}
 .price .valor strong {font-size: 80px;}
 .price .valor em {font-size:60px;}
 .unidade .select {min-height: 50px; font-size: 16px;}
 .unidade div::after {min-height: 50px; line-height: 50px; border-radius: 0 8px 8px 0;}
 .unidade a.select div {margin-bottom: 0; line-height: 48px;}
 .unidade #unidade {border: 1px solid #fff; border-radius: 10px;}
 .unidade #unidade a span {line-height: 18px;}
 .unidade button {position: relative; min-height: 80px; margin-bottom: 20px; font-size: 30px;}
 .area_02 {background-position: center bottom; background: url("../images/bg_section_02_mobile.jpg"); padding: 40px 0 200px 0; background-size: cover;}
 .area_02 ul {padding-left: 0;}
 .area_02 ul span {font-size: 16px;}
 .price .button {font-size: 22px;}
 .type_plan {font-size: 25px;}
 
 .modal {padding: 0;}
 .modal .container {margin: 0;}
 .faq-content {width: 100%;}
 .modal h5 {overflow: visible;}
 .modal h5 a {top: -20px; right: -10px;}
 .modal .card-content h5 {padding-bottom: 20px; margin-bottom: 20px;}
 .modal .card-content {width: 100%; padding: 25px;}
 .modal .card-content span { font-size: 16px;}
 .modal .card-content .plan {margin-bottom: 10px; padding-bottom: 0; line-height: 22px;}
 .modal .card-content ul {float: none; width: 100%;}
 .modal .card-content h6 {padding-top: 20px; margin-top: 20px;}
 .modal .card-content .valores {margin-top: 10px;}
 .modal .card-content .valores p {margin: 20px 0;}
 
}
@media (max-width:320px) {
 .banner h1 svg {width: 80px;}
 .banner h1 span {font-size: 14px; padding: 15px 0 15px 15px;}
 .benefits .box h3 {font-size: 15px;}
 .benefits .box span {font-size: 13px;}
 .benefits .box h4 {font-size: 26px;}
 .price h2 span {font-size: 25px;}
 .price .valor em {font-size: 48px;}
}